<template>
	<div class="input-state" :class="{ show }">
		<Icon v-show="state === 'success'" name="checkmark-circle-2" class="green" width="20" />
		<Icon v-show="state === 'error'" name="alert-circle" class="red" width="20" />
		<Loader v-show="state === 'loading'" :size="20" :border-width="3" :border-color="'gray'" />
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Icon from './Icon.vue'

export default {
	components: {
		Loader,
		Icon,
	},

	props: {
		state: {
			type: String,
		},

		show: {
			type: Boolean,
		},
	},
}
</script>

<style lang="scss" scoped>
.input-state {
	bottom: 6px;
	opacity: 0;
	position: absolute;
	right: 9px;
	transition: $transition;

	&.show {
		opacity: 1;
	}
}
</style>
